let resumeData = {
    "imagebaseurl":"https://rbhatia46.github.io/",
    "name": "Mustafa Hassan",
    "role": "Full Stack Software Engineer",
    "linkedinId": "https://www.linkedin.com/in/mustafa-hassan-280475177/",
    "roleDescription": "I enjoy employing various parts of frontend & backend development and like learning about new technologies, writing technical articles and finding solutions to modern day problems.",
    "socialLinks":[
        {
          "name":"linkedin",
          "url":"https://www.linkedin.com/in/mustafawhassan/",
          "className":"fa fa-linkedin"
        },
        {
          "name":"github",
          "url":"http://github.com/mh2795",
          "className":"fa fa-github"
        },
        {
          "name":"skype",
          "url":"mailto:mustafawhassan@gmail.com",
          "className":"fa fa-envelope"
        }
      ],
    "aboutme":"I am a D.C. based solutions-oriented software engineer with a drive for writing clean and concise code to create a product that enhances user experience. My previous experiences in business and entrepreneurship allow me to think analytically and find unique solutions to problems you may not know exist.",
    "address":"Washington D.C.",
    "website":"https://mh2795.github.io",
    "education":[
      {
        "UniversityName":"General Assembly",
        "specialization":"Software Engineering Immersive",
        "MonthOfPassing":"Dec",
        "YearOfPassing":"2019"
      },
      {
        "UniversityName":"George Mason University",
        "specialization":"B.S. in Business Management",
        "MonthOfPassing":"May",
        "YearOfPassing":"2017"
      }
    ],
    "work":[
      {
        "CompanyName":"General Assembly",
        "specialization":"Software Engineering Immersive",
        "MonthOfLeaving":"Dec",
        "YearOfLeaving":"2019",
        "Achievement1": "- Completed a full-time immersive program that included over 500 hours of professional on-site training in addition to at least 400 hours of self study.",
        "Achievement2": "- Wrote quality, dynamic and functional code to develop a portfolio of individually and collaboratively focused in-class projects.",
        "Achievement3": "- Enhanced leadership, collaboration and technical coding skills through consistent code reviews and peer assessments."
      }
    ],
    "skillsDescription":"Your skills here",
    "skills":[
      {
        "skillname":"HTML5"
      },
      {
        "skillname":"CSS"
      },
      {
        "skillname":"JavaScript"
      },
      {
        "skillname":"Python"
      },
      {
        "skillname":"React JS"
      },
      {
        "skillname":"Node JS"
      },
      {
        "skillname":"Express JS"
      },
      {
        "skillname":"jQuery"
      },
      {
        "skillname":"JSON"
      },
      {
        "skillname":"REST API"
      },
      {
        "skillname":"Django"
      },
      {
        "skillname":"Mongoose"
      },
      {
        "skillname":"MongoDB"
      },
      {
        "skillname":"Peewee Library"
      },
      {
        "skillname":"Storybook"
      },
      {
        "skillname":"Postgresql"
      },
      {
        "skillname":"SQL"
      },
      {
        "skillname":"Git/Github"
      },
      {
        "skillname":"Agile"
      }
    ],
    "portfolio":[
      {
        "name":"OTOI",
        "description":"Personal Tracker App",
        "imgurl": "images/portfolio/OTOI.png",
        "projecturl": "https://mern-group-app-frontend.mustafawhassan.now.sh/"
      },
      {
        "name":"NBA API",
        "description":"Active Player Stats",
        "imgurl":"images/portfolio/nba_api.png",
        "projecturl": "http://github.com/mh2795"
      },
      {
        "name":"CLI Contacts List",
        "description":"Python/CLI App",  
        "imgurl":"images/portfolio/cli_app.png",
        "projecturl": "https://github.com/mh2795/python-command-line-application"
      },
      {
        "name":"Hangman",
        "description":"Javascript Browser Game",
        "imgurl":"images/portfolio/hangman.png",
        "projecturl": "https://mh2795.github.io/hangman-game/"
      }
    ],
    "testimonials":[
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      },
      {
        "description":"This is a sample testimonial",
        "name":"Some technical guy"
      }
    ]
  }
  
  export default resumeData